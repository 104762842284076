<template>
    <div class="app vstack startpage">
        <div v-if="hasParam">
            <div v-show="showpage === ''">
                <div class="content p">
                    <h1>欢迎使用远程视频处置系统</h1>
                    <p>如果您是第一次使用系统</p>
                    <sea-button @action="showpage = 'intro'" class="custombtn">查看使用说明</sea-button>
                    <p>如果已阅读并理解使用说明</p>
                    <sea-button @action="showpage = 'reg'" class="custombtn">开始远程处置</sea-button>
                </div>
            </div>

            <sea-modal
                xclass="panel panel-intro"
                :active="showpage === 'intro'"
                title="使用说明"
                @close="showpage = ''"
            >
                <app-intro></app-intro>
            </sea-modal>

            <sea-modal
                xclass="panel panel-intro"
                :active="showpage === 'reg'"
                title="登记信息"
                @close="showpage = ''"
            >
                <app-reg></app-reg>
            </sea-modal>

            <div v-show="showpage === 'error'">
                <div class="content">请在微信中打开页面</div>
            </div>
        </div>
        <div v-else>hello world</div>
    </div>
</template>

<style type="text/scss">
       .startpage {
          background: #fff;
          color: #2177c3;
          
            p, .p {
              margin-bottom: 1rem;
            }
       }
       
      .content {
        text-align: center;
      }
      
      .content h1 {
        color: #2177c3;
        font-weight: 100;
        font-size: 16pt;
        margin: 4rem 2rem;
      }
      
      .custombtn {
        #margin: 0.2rem 0.2rem;
        #background: #109114;
        display: block;
        margin: 1rem auto;
      }
      
      .sea-button+.sea-button {
          margin-left: auto!important;
       }
</style>

<script>
import SeaButton from "../ui/sea-button"
import SeaModal from "../ui/sea-modal"

export default {
  name: "app-startpage",
  components: {
    SeaModal,
    SeaButton,
    AppIntro: () => import(/* webpackChunkName: 'app-intro' */ "./app-intro"),
    AppReg: () => import(/* webpackChunkName: 'app-reg' */ "./app-reg"),
  },
  data() {
    return {
        hasParam: window.location.href.indexOf('/?room/') !== -1,
        showpage: '',
    }
  },
  mounted() {
    if (!/wechat|chrome|iphone/i.test(navigator.userAgent)) {
      this.showpage = 'error'
      return
    }
  },
  created() {
    if(!this.state.isPC) {
        history.pushState(null, null, window.location.href)
        window.addEventListener("popstate", () => {
            history.pushState(null, null, window.location.href)
            if(!this.state.isPC) this.showpage = ''
        })
    }
  },
  methods: {
   
  }
}
</script>